<template>
    <div>

        <el-form :inline="true" @submit.native.prevent>

            <el-form-item>
                <el-input v-model="listQuery.idFuzzy" :placeholder='$i18n.t("common.SKU_EAN_Encoding")' clearable @keyup.enter.native="handleCurrentChange(1)"></el-input>
            </el-form-item>

            <el-form-item>
                <el-select v-model="listQuery.shelfCountryCode" class="input-width" :placeholder='$i18n.t("common.Countrieslisted")' clearable>
                    <el-option
                            v-for="item in dictionary.country"
                            :key="item.id"
                            :label="item.name"
                            :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-select v-model="listQuery.effectShelf"  class="input-width" :placeholder='$i18n.t("common.state")' clearable>
                    <el-option v-for="item in dictionary.state" :key="item.id" :label="item.name"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-cascader :placeholder="$t('common.Pleaseselectacategory')" :options="this.classifyTree" ref="goodsClassCascader"
                             :props="{checkStrictly: true,value:'goodsClassifyId',label:'title',children:'children'}"  @change="handleCascaderChange" :show-all-levels="false" clearable></el-cascader>
            </el-form-item>


            <el-form-item >
                <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleCurrentChange(1)"
                           style="margin-left: 10px"> {{ $i18n.t("common.query") }}</el-button>
                <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px"
                           size="small"> {{ $i18n.t("common.Reset") }}
                </el-button>
            </el-form-item>

        </el-form>

        <el-table ref="tableData" :data="pageData.list" style="width: 100%" height="500" border  row-key="goodsId"
                  default-expand-all
                  :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
                  @select="onSelect"
                  @select-all="onSelectAll"
                  :row-key="getRowKey"
                  :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
        >

            <el-table-column type="selection" width="55" />
            <!-- 用于显示子表格张开按钮 -->
<!--            <el-table-column prop="quantityType" label="" width="50px" align="center">-->
<!--            </el-table-column>-->
            <el-table-column prop="goodsId"  :label='$i18n.t("common.ProductID")'  align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="goodsCode" :label='$i18n.t("common.SKU_EAN_Encoding")'  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>SKU:{{ scope.row.sku }}</span><br />
                    <span>EAN:{{ scope.row.barCode }}</span><br />
                    <span>{{ $i18n.t("common.coding") }}:{{ scope.row.goodsCode }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="imageUrl" :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image style="height: 100px" v-if="scope.row.imageUrl"
                              :src="ossHost + scope.row.imageUrl" fit="fill"></el-image>
                </template>
            </el-table-column>

            <el-table-column prop="packageQuantity" :label='$i18n.t("common.PackingQuantity")' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="title"  :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>

            <el-table-column  prop="treeList" :label=' $i18n.t("common.Category")' align="center"   show-overflow-tooltip>
                <template slot-scope="scope">
                    <div v-for="(item,index) in scope.row.treeList" :key="index">
                    <span v-for="(item2,index2) in item.parents" :key="index2">
                        <span>{{ item2.title }}></span>
                    </span>
                        <span>{{ item.title }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>

                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                        <span v-if="s.shelf">{{
                            $dict.getDictValue('countrys', s.countryCode)
                        }}<br /></span>
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="goodsSpecials" :label='$i18n.t("common.inventory")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index"
                          v-if="s.shelf&&s.ecomRemainQty">
                        {{ $dict.getDictValue('countrys', s.countryCode) }}:{{ s.ecomRemainQty }}<br/>
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="goodsSpecials" :label="$t('common.ProductPromotion')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                        <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.NewUserPromotion') }}<br/></span>
                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                        </span>
                    </span>
                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                        <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.RegularUserPromotion') }}<br/></span>
                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                        {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                        </span>
                    </span>
                    <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="prices" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="(s, index) in scope.row.prices" :key="index">
                        {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.resultPrice">{{
                            s.resultPrice.toFixed(2)
                        }}</span><br />
                    </span>
                </template>
            </el-table-column>


            <el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $i18n.t("common.Listed") }}</span>
                    <span v-else>{{ $i18n.t("common.Notlisted") }}</span>
                </template>
            </el-table-column>

        </el-table>

        <div class="block" style="display: flex">
            <el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
                           :page-size="listQuery.size" layout="total, prev, pager, next, jumper" :total="pageData.total">
            </el-pagination>

            <el-button type="primary" size="small " style="margin-left: 330px; margin-top: 10px" @click="submit">{{ $i18n.t('common.Confirm') }}</el-button>
            <el-button size="small " style="margin-left: 20px; margin-top: 10px" @click="cancel">{{ $i18n.t('common.cancel') }}</el-button>
        </div>

    </div>
</template>
<script>


    import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
    import i18n from '@/i18n/i18n';
    import dictionaryUtil from '@/utils/dictionaryUtil';

    const defaultDictionary = {
        // 国家
        country: dictionaryUtil.getDict('countrys'),
        stockStatus: [
            {
                id: '1',
                name: i18n.t('common.InStock'),
                value: true
            },
            {
                id: '2',
                name: i18n.t('common.OutOfStock'),
                value: false
            }
        ],
        state: [
            {
                id: '1',
                name: i18n.t('common.Listed'),
                value: true
            },
            {
                id: '2',
                name: i18n.t('common.Notlisted'),
                value: false
            }
        ]
    };

    export default {
        components: {
        },
        name: "addBindGoods",
        props: {
            customParameterId: {
                type: String,
                default: '0'
            }
        },
        data() {
            return {
                defaultListQuery: {
                    idFuzzy: '',
                    shelfCountryCode: '',
                    stockCountryCode: '',
                    classifyId: '',
                    fromPage: "addBindGoods",
                    isStock: '',
                    page: 1,
                    size: 10
                },
                listQuery: {
                    idFuzzy: '',
                    shelfCountryCode: '',
                    stockCountryCode: '',
                    classifyId: '',
                    fromPage: "addBindGoods",
                    isStock: '',
                    effectShelf: '',
                    page: 1,
                    size: 10
                },
                pageData: {
                    list: []
                },
                ossHost: process.env.VUE_APP_OSS_Head,
                dictionary: Object.assign({}, defaultDictionary),
                classifyTree: [],
                selections: {} // 保存已选择过的row
            };
        },
        mounted() {
            this.handleSearchList();
            this.getGoodsClassifyTree();
        },
        methods: {

            handleSearchList( ) {
                this.listQuery = Object.assign({}, this.defaultListQuery);
                this.loadlist();
            },
            //
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.loadlist();
            },
            // 重置
            cleanQuery() {
                this.$refs.goodsClassCascader.$refs.panel.clearCheckedNodes();
                this.listQuery = Object.assign({}, this.defaultListQuery);
                this.loadlist();
            },
            // 商品查询
            loadlist(){

                let temp = JSON.stringify(this.listQuery, (key, value) => {
                    if (value !== null && value !== '') {
                        return value;
                    }
                });
                let listQueryData = JSON.parse(temp);
                listQueryData.customParameterId = this.customParameterId;
                //库存选项校验，两个必须都选
                if (listQueryData.stockCountryCode != null && listQueryData.isStock == null) {
                    this.$message({
                        type: 'warning',
                        message: i18n.t('common.PleaseSelectInventory')
                    });
                    return;
                }
                if (listQueryData.isStock != null && listQueryData.stockCountryCode == null) {
                    this.$message({
                        type: 'warning',
                        message: i18n.t('common.Pleaseselectacountry')
                    });
                    return;
                }
                this.$store.dispatch('postGoodsSearch', listQueryData).then((res) => {
                    this.pageData = res;
                    this.checkRows();
                });
            },

            //选择商品
            handleSelectGoods(row) {

                this.$emit('handleSelectGoods',JSON.parse(JSON.stringify(row)));
            },

            //获取商品树类目
            getGoodsClassifyTree() {
                this.$store.dispatch('getGoodsClassifyTree', {}).then((res) => {
                    this.classifyTree = res;
                });
            },

            handleCascaderChange(val) {

                if (val && val.length > 0) {
                    this.listQuery.classifyId = val[val.length - 1];
                }else {
                    this.listQuery.classifyId = '';
                }
            },
            onSelect(selection, row) {
                if (this.selections[row.goodsId]) {
                    delete this.selections[row.goodsId];
                } else {
                    this.selections[row.goodsId] = row;
                }
            },
            // 全选情况
            onSelectAll(selection) {
                // 全选
                if (selection.length) {
                    selection.forEach((row) => {
                        this.selections[row.goodsId] = row;
                    });
                } else {
                    // 取消全选
                    this.pageData.list.forEach((row) => {
                        delete this.selections[row.goodsId];
                    });
                }
            },
            // 对已选择过的row勾选，返回到上一页时候
            checkRows() {
                const keys = Object.keys(this.selections);
                const rows = this.pageData.list.filter((row) => {
                    return keys.includes(String(row.goodsId));
                });
                this.$nextTick(() => {
                    rows.map((row) => {
                        this.$refs.tableData.toggleRowSelection(row, true);
                    });
                });
            },
            //提交
            submit() {

                var params = {};
                params.goodsIdList = Object.keys(this.selections);
                params.customParameterId = this.customParameterId;

                if (!params.goodsIdList || params.goodsIdList.length === 0) {

                    this.$message({
                        type: 'warning',
                        message: this.$i18n.t("common.Pleaseselectaproduct")
                    });
                }else {

                    this.$store.dispatch('bindCustomParameter', params).then((data) => {
                        this.$message({
                            message: this.$i18n.t("common.Successfullysaved"),
                            type: 'success'
                        });
                        this.$parent.$parent.closeAndRefreshAddBindGoodsForm();
                    });
                }

            },
            cancel() {
                this.$parent.$parent.closeAddBindGoodsForm();
            },
            getRowKey(row) {
                return row.goodsId;
            },
            isShowGoodsPromotion,
            //获取促销价格
            getPromotionGoodsPrice
        }
    };
</script>
<style scoped>

    .dialog-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /* margin-top: 30px; */
    }


</style>
