<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input
                clearable
                v-model="query.userId"
                :placeholder="$i18n.t('common.UserID')"
                style="width: 200px"
                class="filter-item"
                controls-position="right"
            ></el-input>
            <dataSelect
                style="width: 180px"
                class="filter-item"
                v-model="query.platform"
                clearable
                :codeType="'platforms'"
                :placeholder="$i18n.t('common.platform')"
            ></dataSelect>
            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'countrys'"
                :placeholder="$i18n.t('common.country')"
            ></dataSelect>

            <el-date-picker
                class="filter-item"
                v-model="query.logintimeStartAndEnd"
                type="datetimerange"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :range-separator="$i18n.t('common.to')"
                :start-placeholder="$i18n.t('common.starttime')"
                :end-placeholder="$i18n.t('common.Endtime')"
                align="right"
            >
            </el-date-picker>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery">
                {{ $i18n.t('common.query') }}
            </el-button>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px" size="small ">
                {{ $i18n.t('common.Reset') }}
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" @selection-change="selectionChange" border stripe style="width: 100%">
            <el-table-column :label="$i18n.t('common.UserID')" prop="userId" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.userId }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Creationtime')" prop="createTime" align="center">
                <template slot-scope="{ row }">
                    <span>{{ new Date(row.createTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.country')" prop="countrycode" align="center">
                <template slot-scope="{ row }">
                    <span>
                        {{ $dict.getDictValue('countrys', row.countryCode) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="页面地址" prop="webAddress" align="center"></el-table-column>
            <el-table-column label="错误堆栈" prop="error" align="center">
                <template slot-scope="{ row }">
                    <el-tag size="medium" style="width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" @click="openDetail(row)">{{
                        row.error
                    }}</el-tag>
                    
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            style="margin-top: 10px"
            background
            v-show="total > 0"
            @current-change="doQueryList"
            :current-page="query.page"
            :page-size="query.size"
            layout="total, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>

        <el-dialog title="查看日志详情" :visible.sync="diologVisible" v-if="diologVisible" width="1000px" :close-on-click-modal="false">
            <el-descriptions class="margin-top" :column="2" size="medium" border>
                <el-descriptions-item :label='$i18n.t("common.UserID")' :labelStyle="{'width' : '100px'}">{{ showItem.userId }}</el-descriptions-item>
                <el-descriptions-item :label='$i18n.t("common.Creationtime")' :labelStyle="{'width' : '100px'}">{{new Date(showItem.createTime).toLocaleString()  }}</el-descriptions-item>
                <el-descriptions-item :label='$i18n.t("common.IPaddress")' :labelStyle="{'width' : '100px'}">{{ showItem.ipAddress }}</el-descriptions-item>
                <el-descriptions-item :label='$i18n.t("common.platform")' :labelStyle="{'width' : '100px'}">{{ showItem.platform }}</el-descriptions-item>
                <el-descriptions-item :label='$i18n.t("common.country")' :labelStyle="{'width' : '100px'}"> {{ $dict.getDictValue('countrys', showItem.countryCode) }}</el-descriptions-item>
                <el-descriptions-item :label='$i18n.t("common.PageURL")' :labelStyle="{'width' : '100px'}">{{ showItem.webAddress }}</el-descriptions-item>
                <el-descriptions-item label='错误堆栈' :labelStyle="{'width' : '100px'}">
                    {{ showItem.error }}
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import moment from 'moment';

export default {
    name: 'WebLogTable',
    components: {
        Pagination,
        DataSelect,
        moment
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            query: {
                page: 1,
                limit: 10,
                userId: null,
                platform: null,
                logintimeStartAndEnd: null,
                countryCode: null
            },
            selectItems: [],
        
            value: '',
            showItem:{},
            diologVisible:false
        };
    },
    created(){
        this.handleQuery();
    },
    methods: {
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList(1);
        },
        /**
         * 表单重置
         */
        cleanQuery() {
            this.query = [];
            this.doQueryList(1);
        },
        /**
         * 执行列表查询
         */
        doQueryList(page) {
            this.query.page = page;
            this.listLoading = true;
            if (this.query.logintimeStartAndEnd != null) {
                this.query.beginTime = moment(this.query.logintimeStartAndEnd[0]).subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
                this.query.endTime = moment(this.query.logintimeStartAndEnd[1]).subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
                // this.query.beginTime = moment(this.query.logintimeStartAndEnd[0]).format('YYYY-MM-DD HH:mm:ss');
                // this.query.endTime = moment(this.query.logintimeStartAndEnd[1]).format('YYYY-MM-DD HH:mm:ss');
            } else {
                this.query.beginTime = null;
                this.query.endTime = null;
            }

            this.$store
                .dispatch('getWebLogPage', this.query)
                .then((res) => {
                    this.list = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        openDetail(row){
            console.log(row);
            this.diologVisible = true;
            this.showItem = row;
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: 20px;
}

.filter-item {
    margin-left: 10px;
}

</style>
