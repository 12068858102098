var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { margin: "10px 0px 10px 20px" },
              attrs: { type: "success" },
              on: { click: _vm.handleAdd }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$i18n.t("common.Add")) +
                  "\n        "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.list.list, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ParameterID"),
              prop: "customParameterId",
              align: "center",
              "content-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ParameterName"),
              prop: "name",
              align: "center",
              "content-overflow-tooltip": ""
            }
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                label: _vm.$i18n.t("common.ParameterType"),
                prop: "typeDesc",
                align: "center",
                "content-overflow-tooltip": ""
              }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.SearchParameter")))]
          ),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.EnabledStatus"),
              prop: "enable",
              align: "center",
              "content-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66", disabled: "" },
                      model: {
                        value: scope.row.enable,
                        callback: function($$v) {
                          _vm.$set(scope.row, "enable", $$v)
                        },
                        expression: "scope.row.enable"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.AssociatedProductCount"),
              prop: "relationGoodsNum",
              align: "center",
              "content-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.RecentOperator"),
              prop: "recentOperator",
              align: "center",
              "content-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.RecentOperationTime"),
              prop: "updateTime",
              align: "center",
              "content-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              width: "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.showBindGoods(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.AssociatedProducts")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDeleteSingle(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-size": _vm.query.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.list.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.addVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.formTitle,
                visible: _vm.addVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.addVisible = $event
                },
                close: function($event) {
                  return _vm.closeForm()
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "800px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.addCustomParameter,
                    "label-position": "left",
                    size: "small",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.ParameterType"),
                        prop: "typeDesc"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: { disabled: true },
                        model: {
                          value: _vm.addCustomParameter.typeDesc,
                          callback: function($$v) {
                            _vm.$set(_vm.addCustomParameter, "typeDesc", $$v)
                          },
                          expression: "addCustomParameter.typeDesc"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.EnabledStatus"),
                        prop: "enable"
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.addCustomParameter.enable,
                          callback: function($$v) {
                            _vm.$set(_vm.addCustomParameter, "enable", $$v)
                          },
                          expression: "addCustomParameter.enable"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    _vm._l(_vm.langsArr, function(item, index) {
                      return _c(
                        "el-tab-pane",
                        {
                          key: index,
                          attrs: { label: item.langName, name: item.lang }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$i18n.t("common.ParameterName"),
                                    required: ""
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$i18n.t(
                                        "common.EnterParameterName"
                                      ),
                                      maxlength: "30",
                                      "show-word-limit": ""
                                    },
                                    on: { change: _vm.refreshValue },
                                    model: {
                                      value: item.name,
                                      callback: function($$v) {
                                        _vm.$set(item, "name", $$v)
                                      },
                                      expression: "item.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.bindGoodsVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.AssociatedProducts"),
                visible: _vm.bindGoodsVisible,
                "close-on-click-modal": false,
                width: "1200px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.bindGoodsVisible = $event
                },
                close: function($event) {
                  return _vm.closeBindGoodsForm()
                }
              }
            },
            [
              _c("bindGoods", {
                ref: "bindGoods",
                attrs: { customParameterId: _vm.selectCustomParameterId }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }