<template>
    <div class="app-container">
        <div>
            <el-button class="filter-item" style="margin: 10px 0px 10px 20px" type="success" @click="handleAdd">
                {{ $i18n.t('common.Add') }}
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column :label="$i18n.t('common.ParameterID')" prop="customParameterId" align="center" content-overflow-tooltip> </el-table-column>
            <el-table-column :label="$i18n.t('common.ParameterName')" prop="name" align="center" content-overflow-tooltip> </el-table-column>
            <el-table-column :label="$i18n.t('common.ParameterType')" prop="typeDesc" align="center" content-overflow-tooltip>{{ $i18n.t('common.SearchParameter')}}</el-table-column>
            <el-table-column :label="$i18n.t('common.EnabledStatus')" prop="enable" align="center" content-overflow-tooltip>
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.enable" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.AssociatedProductCount')" prop="relationGoodsNum" align="center" content-overflow-tooltip> </el-table-column>
            <el-table-column :label="$i18n.t('common.RecentOperator')" prop="recentOperator" align="center" content-overflow-tooltip> </el-table-column>
            <el-table-column :label="$i18n.t('common.RecentOperationTime')" prop="updateTime" align="center" content-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column :label="$i18n.t('common.operation')" align="center" width="300">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="showBindGoods(row)" class="table-inner-button">{{ $i18n.t('common.AssociatedProducts') }}</el-button>
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">{{ $i18n.t('common.edit') }}</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">{{ $i18n.t('common.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                    style="margin-top: 10px"
                    background
                    @current-change="handleCurrentChange"
                    :current-page="query.page"
                    :page-size="query.size"
                    layout="total, prev, pager, next, jumper"
                    :total="list.total"
            ></el-pagination>
        </div>
        <!-- 新建表单 -->
        <el-dialog :title="formTitle" :visible.sync="addVisible" @close="closeForm()" v-if="addVisible" :close-on-click-modal="false">
            <el-form
                    ref="dataForm"
                    :model="addCustomParameter"
                    label-position="left"
                    size="small"
                    label-width="100px"
                    style="width: 800px; margin-left: 50px"
            >
                <el-form-item :label="$i18n.t('common.ParameterType')" prop="typeDesc">
                    <el-input :disabled="true" v-model="addCustomParameter.typeDesc" style="width: 600px"></el-input>
                </el-form-item>

                <el-form-item :label="$i18n.t('common.EnabledStatus')" prop="enable">
                    <el-switch v-model="addCustomParameter.enable"></el-switch>
                </el-form-item>

                <el-tabs v-model='activeName'>
                    <el-tab-pane v-for='(item,index) in langsArr' :key='index' :label='item.langName' :name='item.lang'>
                        <div>
                            <el-form-item :label="$i18n.t('common.ParameterName')" required>
                                <el-input v-model="item.name" style="width: 100%" :placeholder="$i18n.t('common.EnterParameterName')" @change="refreshValue" maxlength="30" show-word-limit></el-input>
                            </el-form-item>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()"> {{ $i18n.t('common.cancel') }} </el-button>
                <el-button type="primary" @click="handleSubmit()"> {{ $i18n.t('common.confirm') }} </el-button>
            </div>
        </el-dialog>

        <el-dialog
                :title="$i18n.t('common.AssociatedProducts')"
                :visible.sync="bindGoodsVisible"
                @close="closeBindGoodsForm()"
                v-if="bindGoodsVisible"
                :close-on-click-modal="false"
                width="1200px"
        >
            <bindGoods ref="bindGoods" :customParameterId="selectCustomParameterId"></bindGoods>
        </el-dialog>


    </div>
</template>

<script>
    import i18n from '@/i18n/i18n';
    import moment from 'moment';
    import bindGoods from '@/views/customParameter/bindGoods.vue';

    function initAddBean() {
        const addBean = {
            enable: 0,
            type: 'searchParameter',
            typeDesc: '',
            customParameterLangs: []
        };

        return addBean;
    }

    export default {
        name: 'customParameter',
        components: {
            moment,
            bindGoods
        },
        data() {
            return {
                formTitle: '',
                addCustomParameter: initAddBean(),
                list: [],
                total: 0,
                listLoading: false,
                query: {
                    page: 1,
                    limit: 10,
                },
                addVisible: false,
                isAdd: false,
                bindGoodsVisible: false,
                langsArr: [],
                langs: [],
                // formRules: {
                //     enable: [{ required: true, message: '请选择启用状态', trigger: 'blur' }],
                //     typeDesc: [{ required: true, message: '请输入参数类型', trigger: 'blur' }]
                // },
                customParameterLangTips: [
                    {lang: 'zh', tip: this.$i18n.t("common.MandatoryChineseContent")},
                    {lang: 'es', tip: this.$i18n.t("common.MandatorySpanishContent")},
                    {lang: 'it', tip: this.$i18n.t("common.MandatoryItalianContent")},
                    {lang: 'de', tip: this.$i18n.t("common.MandatoryGermanContent")},
                    {lang: 'fr', tip: this.$i18n.t("common.MandatoryFrenchContent")},
                    {lang: 'pl', tip: this.$i18n.t("common.MandatoryPolishContent")},
                    {lang: 'nl', tip: this.$i18n.t("common.MandatoryDutchContent")},
                    {lang: 'en', tip: this.$i18n.t("common.MandatoryEnglishContent")},
                    {lang: 'pt', tip: this.$i18n.t("common.MandatoryPortugueseContent")}
                ],
                selectCustomParameterId: 0,
                activeName: 'zh'
            };
        },
        created() {
            this.doQueryList({ page: 1 });
        },
        mounted() {
            this.getLangDictItems();
        },
        methods: {
            refreshValue() {
                this.$forceUpdate();
            },
            /**
             * 重置多语言
             */
            initLangsArr() {
                this.langsArr = [];
                for (let item of this.langs) {
                    let param = {
                        lang: item.code,
                        langName: item.name,
                        name: null,
                    };
                    this.langsArr.push(param);
                }
                let index = this.langsArr.findIndex(item => item.lang == 'zh');
                let oneObj = this.langsArr.splice(index, 1);
                this.langsArr.splice(0, 0, ...oneObj);

            },
            /**
             * 分页
             */
            handleCurrentChange(val) {
                this.query.page = val;
                this.doQueryList({ page: val });
            },
            closeForm() {
                this.addVisible = false;
                this.doQueryList({ page: 1 });
            },
            closeBindGoodsForm() {
                this.bindGoodsVisible = false;
                this.doQueryList({ page: 1 });
            },
            showBindGoods(row) {
                this.selectCustomParameterId = row.customParameterId;
                this.bindGoodsVisible = true;
            },
            /**
             * 时间戳转码
             */
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property];
                if (!data) {
                    return '';
                } else {
                    return moment(data).format('YYYY-MM-DD HH:mm:ss');
                }
            },
            /**
             * 触发搜索操作
             */
            handleQuery() {
                this.doQueryList({ page: 1 });
            },
            /**
             * 执行列表查询
             */
            doQueryList({ page, limit }) {
                if (page) {
                    this.query.page = page;
                }
                if (limit) {
                    this.query.limit = limit;
                }
                this.$store.dispatch('getCustomParameterPage', this.query).then((res) => {
                    this.list = res;
                });
            },
            /**
             * 删除单条记录
             */
            handleDeleteSingle(row) {
                this.$confirm(this.$i18n.t("common.confirmdeletion") + '?', this.$i18n.t("common.Prompt"), {
                    confirmButtonText: this.$i18n.t("common.Confirm"),
                    cancelButtonText: this.$i18n.t("common.cancel"),
                    type: 'warning'
                }).then(() => {
                        this.$store.dispatch('deleteCustomParameter', row.customParameterId).then((res) => {
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t("common.Successfullydeleted"),
                                type: 'success'
                            });
                        });
                    })
                    .catch(() => {});
            },
            /**
             * 打开编辑表单
             */
            handleUpdate(row) {
                this.formTitle = this.$i18n.t("common.edit");
                this.activeName = 'zh';
                this.isAdd = false;
                this.resetEditForm();

                this.addCustomParameter.enable = row.enable

                //赋值多语言
                row.customParameterLangs.forEach(e => {

                    this.langsArr.forEach(lang => {
                        if (e.lang == lang.lang) {
                            this.$set(lang, 'name', e.name);
                        }
                    })
                });

                this.selectCustomParameterId = row.customParameterId;

                this.addVisible = true;
            },
            /**
             * 重置表单
             */
            resetEditForm() {
                this.addCustomParameter = initAddBean();
                this.addCustomParameter.typeDesc = this.$i18n.t("common.SearchParameters");
                this.initLangsArr();
            },
            /**
             * 重置表单
             */
            resetForm() {
                this.addCustomParameter = initAddBean();
                this.addCustomParameter.typeDesc = this.$i18n.t("common.SearchParameters");
                this.initLangsArr();
            },
            /**
             * 打开新建表单
             */
            handleAdd() {
                this.formTitle = this.$i18n.t("common.Add");
                this.activeName = 'zh';
                this.resetForm();
                this.addVisible = true;
                this.isAdd = true;
            },
            /**
             * 执行新建操作
             */
            handleSubmit() {

                this.addCustomParameter.customParameterLangs = this.langsArr;

                //校验
                for (let customParameterLangTip of this.customParameterLangTips) {

                    for (let item of this.addCustomParameter.customParameterLangs) {

                        if (customParameterLangTip.lang == item.lang) {

                            if (!item.name || item.name == '') {
                                this.$message({
                                    showClose: false,
                                    message: customParameterLangTip.tip,
                                    type: 'warning'
                                });
                                this.activeName = customParameterLangTip.lang;
                                return;
                            }
                        }
                    }
                }

                if (this.isAdd) {
                    this.$refs['dataForm'].validate((valid) => {
                        if (valid) {
                            this.$store.dispatch('saveCustomParameter', this.addCustomParameter).then((data) => {
                                this.addVisible = false;
                                this.doQueryList({ page: 1 });
                                this.$message({
                                    message: this.$i18n.t("common.Successfullysaved"),
                                    type: 'success'
                                });
                            });
                        } else {
                            this.$message({
                                message: this.$i18n.t("common.Savefailed"),
                                type: 'error'
                            });
                            return false;
                        }
                    });
                } else {
                    this.$refs['dataForm'].validate((valid) => {
                        if (valid) {

                            this.addCustomParameter.selectCustomParameterId = this.selectCustomParameterId;

                            this.$store.dispatch('updateCustomParameter', this.addCustomParameter).then((data) => {
                                this.addVisible = false;
                                this.doQueryList({ page: 1 });
                                this.$message({
                                    message: this.$i18n.t("common.Successfullyedited"),
                                    type: 'success'
                                });
                            });
                        } else {
                            this.$message({
                                message: this.$i18n.t("common.Editingfailed"),
                                type: 'error'
                            });

                            this.addVisible = false;
                            return false;
                        }
                    });
                }
            },
            getLangDictItems() {
                let _this = this;
                this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                    _this.langs = res;
                });
            },
            cleanQuery() {
                this.doQueryList({ page: 1 });
            }
        }
    };
</script>
<style scoped>
    .filter-container {
        padding: auto;
        margin-left: 20px;
        padding-top: 20px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 178px !important;
        height: 178px !important;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .textareaDiv{
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    /deep/ .avatar-uploader .el-upload--picture-card {
        width: 178px !important;
        height: 178px !important;
    }
    /deep/ .avatar-uploader .el-progress--circle {
        width: 176px !important;
        height: 176px !important;
        margin-top: 0px !important;
    }
    /deep/ .avatar-uploader .el-progress {
        width: 176px !important;
        height: 176px !important;
        margin-top: 0px !important;
    }
    /deep/ .avatar-uploader .el-progress-circle {
        width: 176px !important;
        height: 176px !important;
        margin-top: 0px !important;
    }
</style>